import img01 from "../assets/images/olympoUrbano/img01.jpg"
import img02 from "../assets/images/olympoUrbano/img02.jpg"
import img03 from "../assets/images/olympoUrbano/img03.jpg"
import img04 from "../assets/images/olympoUrbano/img04.jpg"
import img05 from "../assets/images/olympoUrbano/img05.jpg"
import img06 from "../assets/images/olympoUrbano/img06.jpg"
import img07 from "../assets/images/olympoUrbano/img07.jpg"
import img08 from "../assets/images/olympoUrbano/img08.jpg"
export const OlympoBlock1 = [
  {
    title: `SALÓN DE PASAJEROS`,
    text: `Diseñado con material PVC DECORATIVO y láminas de fibra de vidrio impermeables, de facil limpieza e antinflamable. Piso protegido con aluminio estriado. Iluminación con tecnología LED.`,
    img: img01,
  },
  {
    title: `CABINA DE CONDUCTOR`,
    text: `Tablero de Control con diseño AERODINÁMICO. Cúpula con gravado natural lineas estilizadas decorativas e iluminación propia.`,
    img: img02,
  },
  {
    title: `PUERTAS DE SERVICIO`,
    text: `Puertas de servicio semi automáticas accionadas por el conductor.`,
    img: img03,
  },
  {
    title: `FRONTAL`,
    text: `Modelo AERODINÁMICO. Laminado en fibra de vidrio con refuerzo interno para reducción de daños ocasionados por impactos. Luces LED bifocales con efecto flash y decorativos con iluminación neón.`,
    img: img04,
  },
  {
    title: `POSTERIOR`,
    text: `Laminado en en fibra de vidrio reforzado internamente para absorver impactos. Luces LED bifocales con efecto flash y decorativos con iluminación neon.`,
    img: img05,
  },
  {
    title: `SISTEMA DE CÁMARAS`,
    text: `Sistema de camaras FULL HD con pantalla y gravador DRV incorporado mediante tarjeta SD`,
    textAlt: `*alternativa personalizable`,
    img: img06,
  },
  {
    title: `RUTERO ELECTRÓNICO`,
    text: `Con tecnología LED, display personalizado y seleccionador de rutas programadas.`,
    textAlt: `*alternativa personalizable`,
    img: img07,
  },
  {
    title: `ASIENTOS`,
    text: `Asientos con diseño ergonómico, inyectados en poliuretano flexible para comodidad y resistencia. Y tapizados especialmente para servicio urbano`,
    textAlt: `*alternativa personalizable`,
    img: img08,
  },
]
