import React from "react"
import { WrapperContainer } from "./style"
import { Link } from "gatsby"
import Boton from "../botonFormulario"
const Card = props => {
  return (
    <>
      <img src={props.img} alt="imagen de item" />
      <div className="info">
        <h4>{props.title}</h4>

        {props.text.map ? (
          props.text.map((text, key) => {
            return (
              <p key={key}>
                <span>{text.titulo}</span>: &nbsp;
                <span>{text.text}</span>
              </p>
            )
          })
        ) : (
          <p>{props.text}</p>
        )}

        {props.textAlt ? <p>{props.textAlt}</p> : null}
        {props.url ? (
          <div className="Link">
            {" "}
            <Boton type="span">Ver más</Boton>{" "}
          </div>
        ) : null}
      </div>
    </>
  )
}
const blockLayout = props => {
  return (
    <WrapperContainer tipo={props.page}>
      {/* para validar si poner el subtitulo */}
      {props.subtitle ? <h3 className="subtitle">{props.subtitle}</h3> : null}
      {/* para validar si hay data o no*/}
      <div className="wrapperflex">
        {props.data
          ? props.data.map((dt, key) => {
              const { title, text, img, textAlt, url } = dt
              // console.log(url);
              return url ? (
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="item"
                  key={key}
                >
                  <Link to={url}>
                    <Card img={img} title={title} text={text} url={url} />
                  </Link>
                </div>
              ) : (
                <div
                  key={key}
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  className="item"
                >
                  <Card img={img} title={title} text={text} textAlt={textAlt} />
                </div>
              )
            })
          : null}
      </div>
    </WrapperContainer>
  )
}

export default blockLayout
