import React from "react"
import Layout from "../../../components/layout"
import Hero from "../../../components/olympo/hero"
import img from "../../../assets/images/heroOlympoUrbano.png"
import WrapperBlock from "../../../components/olympo/wrapperBlock"
import Block1 from "../../../components/olympo/block1"
import { OlympoBlock1 } from "../../../data/olympoUrbano"
import Contact from "../../../components/olympo/contact"
const olympoUrbano = () => {
  return (
    <Layout>
      <Hero
        img={img}
        title="OLYMPO URBANO"
        subtitle="Buses urbanos"
        text="Ideal para transporte colectivo de pasajeros"
        crTitle1="Pasajeros sentados"
        crTitle2="Largo"
        crTitle3="Ancho"
        crTitle4="Altura"
        crTitle5="Chasis"
        crText1="27 (conf. 2 X 1)"
        crText2="9             MTS"
        crText3="220           MTS"
        crText4="2.90          MTS"
        crText5="Mitsubishi, Hyundai, Hino, Yutong"
      />
      <WrapperBlock>
        <Block1 data={OlympoBlock1}></Block1>
      </WrapperBlock>
      <Contact></Contact>
    </Layout>
  )
}

export default olympoUrbano
