import React from "react"
import { Hero } from "./style"
const hero = props => {
  return (
    <Hero>
      <div data-aos="flip-up" data-aos-duration="1500" className="container">
        <img src={props.img} alt="hero olympo" />
        <div className="info">
          <h1>{props.title || "undefined"}</h1>
          <h2>{props.subtitle || "undefined"}</h2>
          <p>{props.text || "undefined"}</p>
          <h3 className="titleCaracteristicas">
            CARACTER&Iacute;STICAS T&Eacute;CNICAS
          </h3>
          <div className="caracteristicas">
            <div className="col1">
              <h4>{props.crTitle1 || "undefined"}</h4>
              <h4>{props.crTitle2 || "undefined"}</h4>
              <h4>{props.crTitle3 || "undefined"}</h4>
              <h4>{props.crTitle4 || "undefined"}</h4>
              <h4>{props.crTitle5 || "undefined"}</h4>
            </div>
            <div className="col2">
              <p>{props.crText1 || "undefined"}</p>
              <p>{props.crText2 || "undefined"}</p>
              <p>{props.crText3 || "undefined"}</p>
              <p>{props.crText4 || "undefined"}</p>
              <p>{props.crText5 || "undefined"}</p>
            </div>
          </div>
        </div>
      </div>
    </Hero>
  )
}
export default hero
