import styled from "styled-components"
export const WrapperContainer = styled.div`
  h3,
  h4 {
    font-family: "aller";
  }
  .subtitle{
    font-size:${({theme})=>theme.fontsize.medium};
  }
  .item .info {
    text-align: ${props => (props.tipo ? "null" : "center")};
    color: ${({ theme }) => theme.colors.azul};
  }
  @media (max-width: 500px) {
    .subtitle {
      text-align: center;
      font-size:${({theme})=>theme.fontsize.large};
      font-weight:bold;
    }
  }
`
