import styled from "styled-components"
export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.amarillo};
  padding: 2.5rem 0;
  .wrapperflex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      margin-bottom: 2rem;
      flex: 0 1 calc(100% / 3 - 1em);
      &:nth-child(4),
      &:nth-child(5) {
        flex: 0 1 calc(100% / 2 - 1em);
      }
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 640px) {
    .wrapperflex {
      .item {
        margin-bottom: 2rem;
        flex: 0 1 calc(100% / 2 - 1em);
        &:nth-child(4),
        &:nth-child(5) {
          flex: 0 1 calc(100% / 2 - 1em);
        }
      }
    }
    @media (max-width: 500px) {
      .wrapperflex {
        .item {
          flex: 0 1 100% !important;
          img{
            height:auto;
            width:75%;
            display:flex;
            margin:0 auto;
            object-fit:contain;
          }
        }
      }
    }
  }
`
