import React from "react"
import { Wrapper } from "./style"
import ToggleSwitch from "../../../components/switch"
import Button from "../../../components/botonFormulario"
const contact = props => {
  return (
    <Wrapper>
      <div>
        <h2>FINALMENTE INGRESE SUS DATOS PARA COTIZAR EL PRODUCTO</h2>
        <form
          data-aos="fade-right"
          data-aos-duration="1500"
          className="formulario"
          action=""
          method="post"
        >
          <label htmlFor="Nombres">Nombres y Apellidos</label>
          <input type="text" name="name" id="name" required />
          <label htmlFor="Empresa">Empresa</label>
          <input type="text" name="empresa" id="empresa" required />
          <label htmlFor="Tel">Tel&eacute;fono</label>
          <input type="number" name="telefono" id="telefono" required />
          <label htmlFor="Email">Email</label>
          <input type="email" name="email" id="email" required />
          <div className="flex_preferencias">
            <div className="baño">
              <label htmlFor="Baño">Baño</label>
              <ToggleSwitch item="1" />
            </div>
            <div>
              <label htmlFor="Aire">Aire Acondicionado</label>
              <ToggleSwitch item="2" />
            </div>
          </div>
          <label htmlFor="Indicar">Indicar chasis de su preferencia</label>
          <input type="text" name="chasis" id="chasis" />
          <label htmlFor="Describa">
            Describa el tipo de asientos que necesita
          </label>
          <textarea
            name="tpAsiento"
            id="tpAsiento"
            cols="30"
            rows="10"
          ></textarea>
          <label htmlFor="Observaciones">Observaciones</label>
          <textarea
            name="observaciones"
            id="observaciones"
            cols="30"
            rows="10"
          ></textarea>
          <div className="buttons">
            <Button>CORREO</Button>
            <Button
              type="a"
              url="https://api.whatsapp.com/send?phone=51958888888&text=Hola%20Intramet%20me%20interesan%20tus%20servicios"
            >
              whatsapp
            </Button>
          </div>
        </form>
      </div>
    </Wrapper>
  )
}

export default contact
